import { useMediaQuery } from 'react-responsive'

import './App.css';

/* COMPONENTS IMPORT */
import DrawingSection from './components/DrawingSection.js';
import InfosSection from './components/InfosSection.js';
import PhoneView from './components/PhoneView.js';

/* IMAGES */
const img1 = 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/68407401-69a9-4ecd-8b00-0025fb7d7905/dd8mu26-797e767d-2d88-45a3-b681-32deb3bc5830.png/v1/fill/w_1024,h_1364,q_80,strp/fire_by_hellowizz_dd8mu26-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTM2NCIsInBhdGgiOiJcL2ZcLzY4NDA3NDAxLTY5YTktNGVjZC04YjAwLTAwMjVmYjdkNzkwNVwvZGQ4bXUyNi03OTdlNzY3ZC0yZDg4LTQ1YTMtYjY4MS0zMmRlYjNiYzU4MzAucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.hbbGg-8YyQTefLnQK38pY2q7Y6CM9--fj-JRDOxy7Ps';
const img2 = 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/68407401-69a9-4ecd-8b00-0025fb7d7905/dd9vqjr-31b6e187-ee1b-4ef5-accc-dd98b60267dc.png/v1/fill/w_1024,h_1448,q_80,strp/taste_good_by_hellowizz_dd9vqjr-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTQ0OCIsInBhdGgiOiJcL2ZcLzY4NDA3NDAxLTY5YTktNGVjZC04YjAwLTAwMjVmYjdkNzkwNVwvZGQ5dnFqci0zMWI2ZTE4Ny1lZTFiLTRlZjUtYWNjYy1kZDk4YjYwMjY3ZGMucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.IHhpp5WJVqyAbZLbrVAc7Vqp0wmWRDe2wrhKJjMU3Es';
const img3 = 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/68407401-69a9-4ecd-8b00-0025fb7d7905/de4h49h-1ce67f13-ef31-4fb2-afde-08bc1392163a.png/v1/fill/w_1024,h_1423,q_80,strp/linked_haters_by_hellowizz_de4h49h-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTQyMyIsInBhdGgiOiJcL2ZcLzY4NDA3NDAxLTY5YTktNGVjZC04YjAwLTAwMjVmYjdkNzkwNVwvZGU0aDQ5aC0xY2U2N2YxMy1lZjMxLTRmYjItYWZkZS0wOGJjMTM5MjE2M2EucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.NLNtVt2Nl6-mxg88fH1-Wf8hP1MT0jIS1vQcD_CmfQQ';
const img4 = 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/68407401-69a9-4ecd-8b00-0025fb7d7905/de4h4dt-5fd008ff-883f-42de-bbb6-359b74c0dfe7.png/v1/fill/w_1024,h_1436,q_80,strp/t_i_r_e_d_by_hellowizz_de4h4dt-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTQzNiIsInBhdGgiOiJcL2ZcLzY4NDA3NDAxLTY5YTktNGVjZC04YjAwLTAwMjVmYjdkNzkwNVwvZGU0aDRkdC01ZmQwMDhmZi04ODNmLTQyZGUtYmJiNi0zNTliNzRjMGRmZTcucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.P9NFrN49uczq-3HKzG9AtpCG94nDOXfjYScc-Qwcrgg';
const img5 = 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/68407401-69a9-4ecd-8b00-0025fb7d7905/de4h4g1-a2c892af-5f89-4a12-bc81-d512e1cc33cb.png/v1/fill/w_1024,h_1414,q_80,strp/ykus_by_hellowizz_de4h4g1-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTQxNCIsInBhdGgiOiJcL2ZcLzY4NDA3NDAxLTY5YTktNGVjZC04YjAwLTAwMjVmYjdkNzkwNVwvZGU0aDRnMS1hMmM4OTJhZi01Zjg5LTRhMTItYmM4MS1kNTEyZTFjYzMzY2IucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.u3jZCHgrMcAsUpZSsjBSF_DuDsNLtGDqaLkHlBhtLCw';
const img6 = 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/68407401-69a9-4ecd-8b00-0025fb7d7905/de5c84x-56a85ea5-f06f-4b64-8057-de66bec84766.png/v1/fill/w_1024,h_1517,q_80,strp/no_s_dinner_by_hellowizz_de5c84x-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTUxNyIsInBhdGgiOiJcL2ZcLzY4NDA3NDAxLTY5YTktNGVjZC04YjAwLTAwMjVmYjdkNzkwNVwvZGU1Yzg0eC01NmE4NWVhNS1mMDZmLTRiNjQtODA1Ny1kZTY2YmVjODQ3NjYucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.tDjxq2GrnhvrY2gF0N2gQDvCc6517eVrXMtA6NJONSM';
const img7 = 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/68407401-69a9-4ecd-8b00-0025fb7d7905/df1ihbg-4bfb24f6-3188-47cb-a7bd-1ad5655fcc5e.png/v1/fill/w_1024,h_1532,q_80,strp/oakiishaargh_by_hellowizz_df1ihbg-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTUzMiIsInBhdGgiOiJcL2ZcLzY4NDA3NDAxLTY5YTktNGVjZC04YjAwLTAwMjVmYjdkNzkwNVwvZGYxaWhiZy00YmZiMjRmNi0zMTg4LTQ3Y2ItYTdiZC0xYWQ1NjU1ZmNjNWUucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.j7w9KFIsAsO05WYNlyD4KO6dre9k-zUdmfT6HxQ-8_I';
const img8 = 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/68407401-69a9-4ecd-8b00-0025fb7d7905/df1ih83-0c2ccb20-2c9a-49c2-83db-100c36ff36f3.png/v1/fill/w_1024,h_1536,q_80,strp/electric_tears_by_hellowizz_df1ih83-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTUzNiIsInBhdGgiOiJcL2ZcLzY4NDA3NDAxLTY5YTktNGVjZC04YjAwLTAwMjVmYjdkNzkwNVwvZGYxaWg4My0wYzJjY2IyMC0yYzlhLTQ5YzItODNkYi0xMDBjMzZmZjM2ZjMucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.smNf0lQsH8PvHt8ALyJuNMJIAKzTfwrLUXs30qeENL0';
const img9 = 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/68407401-69a9-4ecd-8b00-0025fb7d7905/df1ihh8-04c056e6-524b-4b04-acc0-d9ddf1b96b3e.png/v1/fill/w_1024,h_1432,q_80,strp/promare___puzzle_by_hellowizz_df1ihh8-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9MTQzMiIsInBhdGgiOiJcL2ZcLzY4NDA3NDAxLTY5YTktNGVjZC04YjAwLTAwMjVmYjdkNzkwNVwvZGYxaWhoOC0wNGMwNTZlNi01MjRiLTRiMDQtYWNjMC1kOWRkZjFiOTZiM2UucG5nIiwid2lkdGgiOiI8PTEwMjQifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.QuXDzgxdm3z2OQuFKiDJ9fLHjE2s-SLg_PMiz-JP59I';
const img10 = 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/68407401-69a9-4ecd-8b00-0025fb7d7905/df1ihjr-9b166b14-7d29-4f53-b779-ca025dbd72fa.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzY4NDA3NDAxLTY5YTktNGVjZC04YjAwLTAwMjVmYjdkNzkwNVwvZGYxaWhqci05YjE2NmIxNC03ZDI5LTRmNTMtYjc3OS1jYTAyNWRiZDcyZmEucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.l_zCFpk-5zP9LwaOTiTELJ3Jy3DOMaiR6f3aUzuKUtk';

const tabOfImgs = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];

function App() {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

	return (
		<div className="App">
		    {!isTabletOrMobile ?
					<>
						<div className="infos-container" style={{ width : '67%'}}>
							<InfosSection isTabletOrMobile={isTabletOrMobile}/>
						</div>
						<DrawingSection tabImgs={tabOfImgs} duration={6}/>
					</> :
					<PhoneView />
				}
		</div>
	);
}

export default App;
