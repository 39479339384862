import React, { useState } from 'react';

/* IMG */
import angleLeft from '../img/icons/angle-left.svg';
import angleRight from '../img/icons/angle-right.svg';

/* CONST */
const quos_exp = <div className="experience-wrapper">
  <div className="experience-header">
    <div className="experience-title">
      <div>QUOS</div>
      <div style={{ fontSize: '25px' }}>[2021-2022]</div>
    </div>
    <img alt="quosAir" src='https://www.quosair.fr/wp-content/uploads/2021/09/QuosAir.png' style={{ height: '100%'}}/>
  </div>
  <div className="experience-subtitle">
    <div style={{ fontWeight: '600', marginBottom: '5px' }}>Développement Web & Design</div>
    <div className='experience-description'>
      <div style={{ marginTop: '7px' }}>
        - Développement from scratch d'une plateforme responsive à destination des clients et connectée aux capteurs QuosAir.
        <div style={{ fontSize: '15px', fontStyle: 'italic', marginTop: '2px'}}>(Visualisations des données & d'analyses, gestion des capteurs, créations de rapports)</div>
      </div>
      <div style={{ marginTop: '7px' }}>
        - Développement from scratch d'un CRM.
        <div style={{ fontSize: '15px', fontStyle: 'italic', marginTop: '2px'}}>(Gestion des clients, des utilisateurs, des capteurs et envois de downlinks.)</div>
      </div>
      <div style={{ marginTop: '7px' }}>
        - Responsable Design de l'entreprise.
        <div style={{ fontSize: '15px', fontStyle: 'italic', marginTop: '2px'}}>(Créations graphiques & Web Design)</div>
      </div>
      <div style={{ marginTop: '7px' }}>
        <i>React.js, Hooks, Tailwind, material-ui, Redux, Axios, Node.js, Illustrator, Photoshop, After Effect </i></div>
      </div>
  </div>
</div>

const webtoon_exp = <div className="experience-wrapper">
  <div className="experience-header">
    <div className="experience-title">
      <div>WEBTOON</div>
      <div style={{ fontSize: '25px' }}>[2020]</div>
    </div>
    <img alt="quosAir" src='https://upload.wikimedia.org/wikipedia/commons/0/09/Naver_Line_Webtoon_logo.png' style={{ height: '100%'}}/>
  </div>
  <div className="experience-subtitle">
    <div style={{ fontWeight: '600', marginBottom: '5px' }}>Réalisation d’une bande dessinée Webtoon</div>
    <div className='experience-description'>
      <div style={{ marginTop: '7px' }}>
        Étant passionnée de dessin et de bande dessinée depuis longtemps, je me suis consacrée à la réalisation d’un webtoon en <span style={{ fontFamily: 'calibri', fontWeight: 600 }}>2020</span>.
      </div>
      <div style={{ marginTop: '7px' }}>
        Cela m’a demandé de l’oganisation, de l’autonomie et de la persévérence.
      </div>
      <div style={{ marginTop: '7px' }}>
        <a href='https://www.webtoons.com/fr/challenge/linked-haters/list?title_no=461285'>https://www.webtoons.com/fr/challenge/linked-haters</a>
      </div>
    </div>
  </div>
</div>

const exane_exp = <div className="experience-wrapper">
  <div className="experience-header">
    <div className="experience-title">
      <div>EXANE</div>
      <div style={{ fontSize: '25px' }}>[2018 - 2019]</div>
    </div>
    <img alt="quosAir" src='https://www.exane.com/corporate/images/logo.jpg' style={{ height: '100%'}}/>
  </div>
  <div className="experience-subtitle">
    <div style={{ fontWeight: '600', marginBottom: '5px' }}>Développement d’une application CRM mobile from scratch en React.js</div>
    <div className='experience-description'>
      <div style={{ marginTop: '7px' }}>
        - App de gestion de rendez-vous, contacts, événements et compte rendus.
      </div>
      <div style={{ marginTop: '7px' }}>
        - Travail sur tout le design et le développement front
      </div>
      <div style={{ marginTop: '7px' }}>
        - Application gérée avec Git et Jenkins, développée sur IntelliJ
      </div>
      <div style={{ marginTop: '7px' }}>
        - Développement en sprints d’une semaine
      </div>
      <div style={{ marginTop: '7px' }}>
        <i>React.js, Redux, HTML, CSS, Java, Webservices Rest, Illustrator</i>
      </div>
    </div>
  </div>
</div>

const modulo_exp = <div className="experience-wrapper">
  <div className="experience-header">
    <div className="experience-title">
      <div>MODULO PI</div>
      <div style={{ fontSize: '25px' }}>[2017]</div>
    </div>
    <img alt="quosAir" src='https://www.modulo-pi.com/wp-content/uploads/2015/11/logomodulo.png' style={{ height: '100%'}}/>
  </div>
  <div className="experience-subtitle">
    <div style={{ fontWeight: '600', marginBottom: '5px' }}>Ajouts de fonctionnalités au logiciel de Modulo Kinetic</div>
    <div className='experience-description'>
      <div style={{ marginTop: '7px' }}>
        - Beaucoup de travail sur des documentation, à comprendre les outils tiers.
      </div>
      <div style={{ marginTop: '7px' }}>
        - Réflexion sur la mise en page des fonctionnalités sur l’application.
      </div>
      <div style={{ marginTop: '7px' }}>
        <i>QtCreator, C++, Mapping, Modulo Kinetic</i>
      </div>
    </div>
  </div>
</div>

const exp_tab = [{nextYear: '2018', show :modulo_exp}, {nextYear: '2020', show: exane_exp, previousYear: '2017'}, {nextYear: '2021', show: webtoon_exp, previousYear: '2019'}, {show: quos_exp, previousYear: '2020'}];


export default function Experiences () {

  const [currentExp, setCurrentExp] = useState(3);

  return (
    <div className="experiences-container">

      <h1 className="title">Experiences</h1>

      <div className="showExp-container">
        <div style={{width: '50px', marginRight: '25px'}}>
          {exp_tab[currentExp]?.previousYear &&
            <div style={{ fontFamily: 'Bebas Neue' }}>
              <div className='arrow-move-left'>
                <img alt="left" src={angleLeft} style={{ cursor: 'pointer', width: '40px' }} onClick={() => setCurrentExp(currentExp - 1)}/>
              </div>
              {exp_tab[currentExp].previousYear}
            </div>
          }
        </div>
        <div style={{ width: 'calc(100% - 100px)'}}>{exp_tab[currentExp]?.show}</div>
        <div style={{ width: '50px', marginLeft: '25px'}}>
          {exp_tab[currentExp]?.nextYear &&
            <div style={{ fontFamily: 'Bebas Neue' }}>
              <div className='arrow-move-right'>
                <img alt="rigt" src={angleRight} style={{ cursor: 'pointer', width: '40px' }} onClick={() => setCurrentExp(currentExp + 1)}/>
              </div>
              {exp_tab[currentExp].nextYear}
            </div>
          }
        </div>
      </div>

    </div>
  );
}
