import React from 'react';

/* CSS */
import './PhoneView.css';

/* IMAGES */
import ProfilePic from '../img/Me.png';

export default function InfosSection() {
  return (
    <div className='phoneView-container'>
      <h1 className="myName" >HELOISE ROUSSEL</h1>
      <a href="mailto:heloroussel@hotmail.fr">heloroussel@hotmail.fr</a>
      <div className='phoneNumber'>+33 6 50 22 58 98</div>
      <div className="image">
        <img alt="me-drawing" src={ProfilePic} style={{ width: '100%', height: '100%'}}/>
      </div>
      <div className='description'>
        <div style={{color: "#7E7F98"}}>
          Oui le responsive c'est cool, et moi aussi j'adore ça !
          C'est long, et j'aimerais prendre le temps de bien le faire...
        </div>
        <div style={{ marginTop: '10px', color: "#4A4861" }}>
          <b>Alors pour l'instant, merci de bien vouloir visionner mon CV sur un écran d'ordinateur !</b>
        </div>
      </div>
    </div>
  );
};
